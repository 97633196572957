.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
    height: 100vh;
    flex-direction: row;
    overflow: hidden;
}

.left-container {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    max-width: 550px;
    padding-right: 20px;
    height: 440px;
}

.form-style {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    max-width: 530px;

}

@media screen and (max-width: 1200px) {
    .container {
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .left-container {
        height: 300px;
        margin-bottom: 20px;
        margin-top: 50px

    }
}

@media screen and (max-width: 1200px) {
    .form-style {
        max-width: 530px;
        margin-bottom: 50px;
    }
}